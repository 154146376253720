import './Onboarding.css';

type MenuItems = Array<React.ReactNode>;

function Onboarding({ menuItems }: { menuItems: MenuItems }) {

    return (
        <div className="onboarding">
            <div className="onboarding__header">
                <img className='onboarding__logo' src="/android-chrome-192x192.png" />
                <h1 className="onboarding__title">Port Salut</h1>
            </div>
            <div className="onboarding__scroll-container">
                <div className="onboarding__scroll-menu">
                    {menuItems.map((item, index: number) => {
                        return (
                            <div key={index} className="onboarding__scroll-item">
                                {item}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default Onboarding;
