import React, { createContext, useContext, useState } from 'react';
import GameDTO from "./models/GameDTO";
import PlayerDTO from "./models/PlayerDTO";

interface GameContextProps {
    gameId: string | null;
    setGameId: (gameId: string | null) => void;

    player: PlayerDTO | null;
    setPlayer: (player: PlayerDTO | null) => void;

    gameState: GameDTO | null;
    setGameState: (gameState: GameDTO | null) => void;
}

const GameContext = createContext<GameContextProps | undefined>(undefined);

export function useGame() {
    const context = useContext(GameContext);
    if (!context) {
        throw new Error('useGame must be used within a GameProvider');
    }
    return context;
}

export function GameProvider({ children }: { children: React.ReactNode }) {
    const [gameId, setGameId] = useState<string | null>(null);
    const [gameState, setGameState] = useState<GameDTO | null>(null);
    const [player, setPlayer] = useState<PlayerDTO | null>(null);

    const value: GameContextProps = {
        gameId,
        gameState,
        player,
        setGameId,
        setGameState,
        setPlayer
    };

    return <GameContext.Provider value={value}>{children}</GameContext.Provider>;
}