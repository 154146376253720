import axios from 'axios';
import GameDTO from "../models/GameDTO";
import GameActionDTO from "../models/GameActionDTO";
import {Config, loadConfig} from "../config/ConfigLoader";
import {Simulate} from "react-dom/test-utils";

axios.interceptors.request.use(async (config) => {
    config.withCredentials = true;

    const loadedConfig = await loadConfig().then(config => config);
    if (loadedConfig) {
        config.baseURL = loadedConfig.backendUrl;
    } else {
        console.error("Configuration issue - no backend url provided.");
    }

    return config;
});

interface CreateGameRequest {
    playerName: string;
    password: string;
}
// Function to create a new game
async function createGame(name: string, password: string): Promise<GameDTO> {
    const request: CreateGameRequest = {
        playerName: name,
        password: password,
    };

    try {
        const response = await axios.post(`/games`, request);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }

}

interface JoinGameRequest {
    playerName: string,
    password: string
}

// Function to join a game
async function joinGame(gameId: string, name: string, password: string): Promise<GameDTO> {
    const request: JoinGameRequest = {
        playerName: name,
        password: password,
    };

    try {
        const response = await axios.post(`/games/${gameId}`, request);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }

}

// Function to add a player to an existing game
async function addPlayer(gameId: string, playerName: string): Promise<GameDTO> {
    const response = await axios.post(`/games/${gameId}/players`, playerName);
    return response.data;
}

// Function to start a game
async function startGame(gameId: string): Promise<GameDTO> {
    const response = await axios.post(`/games/${gameId}/start`);
    return response.data;
}

async function executeGameAction(gameId: string, gameAction: GameActionDTO): Promise<GameDTO> {
    const actionId = gameAction.id;
    const response = await axios.post(`/games/${gameId}/actions/${actionId}/execute`);
    return response.data;
}

// Function to draw a card from the deck
async function drawCard(gameId: string): Promise<GameDTO> {
    const response = await axios.post(`/games/${gameId}/deck/draw-card`);
    return response.data;
}

// Function to get the game state
async function getGameState(gameId: string): Promise<GameDTO> {
    const response = await axios.get(`/games/${gameId}`);
    return response.data;
}

// Function to repel a ship
async function repelShip(gameId: string): Promise<GameDTO> {
    const response = await axios.post(`/games/${gameId}/harbor/repel-ship`);
    return response.data;
}

// Function to enforce taxes
async function enforceTaxes(gameId: string): Promise<GameDTO> {
    const response = await axios.post(`/games/${gameId}/enforce-taxes`);
    return response.data;
}

// Function to end the discovery phase
async function endDiscoveryPhase(gameId: string): Promise<GameDTO> {
    const response = await axios.post(`/games/${gameId}/end-discovery`);
    return response.data;
}

// Function to end the trade and hire phase
async function endTradeAndHirePhase(gameId: string): Promise<GameDTO> {
    const response = await axios.post(`/games/${gameId}/end-trade-and-hire`);
    return response.data;
}

// Function to end the secondary turn
async function endSecondaryTurn(gameId: string): Promise<GameDTO> {
    const response = await axios.post(`/games/${gameId}/end-secondary-turn`);
    return response.data;
}

// Function to claim an expedition
async function claimExpedition(gameId: string, expeditionId: string): Promise<GameDTO> {
    const response = await axios.post(`/games/${gameId}/expeditions/${expeditionId}/claim`);
    return response.data;
}

// Function to hire a person
async function hirePerson(gameId: string, cardId: string): Promise<GameDTO> {
    const response = await axios.post(`/games/${gameId}/harbor/cards/${cardId}/hire`);
    return response.data;
}

// Function to take a ship
async function takeShip(gameId: string, cardId: string): Promise<GameDTO> {
    const response = await axios.post(`/games/${gameId}/harbor/cards/${cardId}/take-ship`);
    return response.data;
}


export {
    createGame,
    joinGame,
    addPlayer,
    startGame,
    drawCard,
    getGameState,
    repelShip,
    enforceTaxes,
    endDiscoveryPhase,
    endTradeAndHirePhase,
    endSecondaryTurn,
    claimExpedition,
    hirePerson,
    takeShip,
    executeGameAction
    // Add more functions here
};
